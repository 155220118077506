<template>
  <div class="users">
    <el-dialog
      title="更换手机号"
      :visible.sync="centerDialogVisible1"
      width="580px"
      center
      :close-on-click-modal="false"
    >
      <div class="input-box">
        <div class="item_input">
          <span>请输入手机号：</span
          ><span><el-input v-model="changePhone" placeholder="请输入手机号"></el-input></span>
        </div>
      </div>
      <div class="input-box">
        <span>请输入验证码:</span
        ><span class="flexS"
          ><el-input v-model="changeYzm" placeholder="请输入验证码" class="changeYzm"></el-input>
          <el-button
            style="width: 113px"
            type="primary"
            :disabled="disabled"
            @click="ucVerifyCode"
            >{{ btntxt }}</el-button
          ></span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="ucChangePhone">确 定</el-button>
        <el-button @click="centerDialogVisible1 = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改密码"
      :close-on-click-modal="false"
      :visible.sync="centerDialogVisible"
      width="580px"
      center
    >
      <div class="input-box">
        <span>请输入新密码:</span
        ><span class="flexS"
          ><el-input v-model="passinput1" type="password" placeholder="请输入新密码"></el-input
        ></span>
      </div>
      <div class="input-box">
        <span>请确认新密码:</span
        ><span class="flexS"
          ><el-input v-model="passinput2" type="password" placeholder="请确认新密码"></el-input
        ></span>
      </div>
      <div class="input-box">
        <span>请输入手机号:</span
        ><span class="flexS"
          ><el-input v-model="passinput3" placeholder="请输入手机号" disabled></el-input
        ></span>
      </div>
      <div class="input-box">
        <span>请输入验证码:</span
        ><span class="flexS"
          ><el-input v-model="passinput4" placeholder="请输入验证码" class="changeYzm"></el-input
          ><el-button
            style="width: 113px"
            type="primary"
            :disabled="disabled1"
            @click.native="passVerifyCode"
            >{{ btntxt1 }}</el-button
          ></span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="changePassword">确 定</el-button>
        <el-button @click="centerDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <div class="container">
      <div class="main">
        <div class="nav_path">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>个人中心</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="user_main">
          <el-tabs type="border-card">
            <el-tab-pane label="个人资料">
              <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="真实姓名：" required>
                  <el-input v-model="form.userName" placeholder="请输入" maxlength="6"></el-input>
                </el-form-item>
                <el-form-item ref="logo" label="头像：" required>
                  <!-- <el-upload
                    class="avatar-uploader"
                    action="#"
                    :http-request="upLoad"
                    :show-file-list="false"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload> -->
                  <CropperImage
                    ref="CropperImage"
                    v-model="form.headPic"
                    :upload-pictures="$refs.logo"
                  ></CropperImage>
                </el-form-item>
                <el-form-item label="性别：" required>
                  <el-radio-group v-model="form.resource">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" @click="onSubmit">提交</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="账号设置">
              <div>
                <span class="item_name">当前登录帐号：{{ form.userName }}</span>
                <el-divider></el-divider>
              </div>
              <div class="box_item">
                <div>
                  <p>
                    <span class="">手机号</span><span class="box_item_phone">{{ hidePhone }}</span>
                  </p>
                  <p class="tip_item">绑定后可以用该手机号登录</p>
                </div>
                <div class="changeButton" @click="centerDialogVisible1 = true">
                  更改
                </div>
              </div>
              <div class="box_item">
                <p class="password_item">
                  <span>密码</span>
                  <span v-show="hasPassWord == true" class="tip_item">已设置</span
                  ><span v-show="hasPassWord == false" class="tip_item">未设置</span>
                </p>
                <div class="changeButton" @click="centerDialogVisible = true">
                  更改
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="意见反馈">
              <div class="tip">您的任何建议都可以对平台提出，我们会认真对待。</div>
              <el-select v-model="region" placeholder="请选择问题类型">
                <el-option label="平台系统相关" value="1"></el-option>
                <el-option label="班级、课程相关" value="2"></el-option>
                <el-option label="学堂设置相关" value="3"></el-option>
                <el-option label="其他" value="4"></el-option>
              </el-select>
              <el-input
                v-model="textarea"
                type="textarea"
                :rows="8"
                placeholder="请输入内容"
                maxlength="256"
                show-word-limit
                class="resizeNone"
              >
              </el-input>
              <el-button type="primary" @click="onSubmit2">提交</el-button>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CropperImage from './components/CropperImage'
import { uploadimage } from '@/api/create'
import { setCookie } from '@/utils/cookies'
import {
  getUser,
  getUserData,
  ucVerifyCode,
  ucChangePhone,
  passVerifyCode,
  updatePassword,
  suggest,
} from '@/api/login'
import to from 'await-to'
export default {
  components: {
    CropperImage,
  },
  data() {
    return {
      form: {
        userName: '',
        resource: '',
      },
      imageUrl: '',
      phoneNub: '',
      passinput3: '',
      hidePhone: '',
      hasPassWord: '',
      centerDialogVisible: false,
      centerDialogVisible1: false,
      changePhone: '',
      changeYzm: '',
      disabled: false,
      time: 60,
      btntxt: '发送验证码',
      passinput1: '',
      passinput2: '',
      passinput4: '',
      disabled1: false,
      time1: 60,
      btntxt1: '发送验证码',
      region: '',
      textarea: '',
    }
  },
  mounted() {
    this.getUser()
  },
  methods: {
    async getUser() {
      const [res, err] = await to(getUser())
      if (err) return this.$message.warning(err.msg)
      this.phoneNub = res.data.phone
      this.passinput3 = res.data.phone
      this.hidePhone = res.data.hidePhone
      this.form.userName = res.data.userName
      this.imageUrl = res.data.headPic
      this.form.headPic = res.data.headPic
      this.form.resource = res.data.sex
      this.hasPassWord = res.data.hasPassWord
    },
    async upLoad(file) {
      const params = new FormData() //创建FormData
      params.append('fileTypeCode', 'F001')
      params.append('file', file.file)
      params.append('fileName', file.file.name)
      const [res, err] = await to(uploadimage(params))
      if (err) return this.$message.warning(err.msg)
      if (res.code == 0) {
        this.imageUrl = res.data.objectUrl
        this.$message({
          message: '上传成功',
          type: 'success',
        })
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
        })
      }
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.includes('image')
      if (!isImage) {
        this.$message.error('上传文件类型必须是图片!')
      }
      return isImage
    },
    async onSubmit() {
      const [, err] = await to(
        getUserData({
          headPic: this.imageUrl,
          userName: this.form.userName,
          sex: this.form.resource,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      setCookie('headPic', this.imageUrl) //
      setCookie('userName', this.form.userName) //
      setCookie('sex', this.form.resource) //
      this.$message({
        message: '提交成功',
        type: 'success',
      })
      setTimeout(function() {
        window.location.reload()
      }, 1000)
    },
    timer() {
      if (this.time > 0) {
        this.disabled = true
        this.time--
        this.btntxt = this.time + '秒'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.btntxt = '发送验证码'
        this.disabled = false
      }
    },
    timer1() {
      if (this.time1 > 0) {
        this.disabled1 = true
        this.time1--
        this.btntxt1 = this.time1 + '秒'
        setTimeout(this.timer1, 1000)
      } else {
        this.time1 = 0
        this.btntxt1 = '发送验证码'
        this.disabled1 = false
      }
    },
    //获取验证码
    async ucVerifyCode() {
      if (this.changePhone == '') {
        this.$message.error('请输入电话号码')
        return
      }
      const [, err] = await to(ucVerifyCode({ phone: this.changePhone }))
      if (err) return this.$message.warning(err.msg)
      this.time = 60
      this.timer()
    },
    //点击确认修改电话
    async ucChangePhone() {
      if (this.changePhone == '') {
        this.$message.error('请输入电话号码')
        return
      }
      if (this.changeYzm == '') {
        this.$message.error('请输入验证码')
        return
      }
      const [, err] = await to(
        ucChangePhone({ phone: this.changePhone, verifyCode: this.changeYzm }),
      )
      if (err) return this.$message.warning(err.msg)
      this.$message.success('请输入电话号码')
      setCookie('phone', this.changePhone) //手机号
      var pat = /(\d{3})\d*(\d{4})/
      var hidePhone = this.changePhone.replace(pat, '$1****$2')
      setCookie('hidePhone', hidePhone) //手机号
      setTimeout(function() {
        window.location.reload()
      }, 1000)
    },
    async passVerifyCode() {
      if (this.passinput3 == '') {
        this.$message.error('电话号码不能为空')
        return
      }
      const [, err] = await to(passVerifyCode({ phone: this.passinput3 }))
      if (err) return this.$message.warning(err.msg)
      this.time1 = 60
      this.timer1()
    },
    async changePassword() {
      if (this.passinput1 == '') {
        this.$message.error('请输入新密码')
        return
      }
      if (this.passinput2 == '') {
        this.$message.error('请确认新密码')
        return
      }
      if (this.passinput3 == '') {
        this.$message.error('输入手机号')
        return
      }
      if (this.passinput4 == '') {
        this.$message.error('请输入验证码')
        return
      }
      const [, err] = await to(
        updatePassword({
          password: this.passinput1,
          confirmPassword: this.passinput2,
          phone: this.passinput3,
          verifyCode: this.passinput4,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      this.$message.success('修改密码成功')
      setTimeout(function() {
        window.location.reload()
      }, 1000)
    },
    async onSubmit2() {
      if (this.region == null || this.region == '') {
        this.$message.error('请选择问题类型')
        return
      }
      if (this.textarea == null || this.textarea == '') {
        this.$message.error('请输入问题内容')
        return
      }
      const [, err] = await to(suggest({ feedBack: this.textarea, type: Number(this.region) }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('提交成功')
      setTimeout(function() {
        window.location.reload()
      }, 1000)
    },
  },
}
</script>
<style scoped>
.users {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}
.container {
  display: flex;
  justify-content: center;
}
.main {
  width: 1200px;
}
.nav_path {
  margin-top: 55px;
}
.user_main {
  width: 1200px;
  height: 570px;
  background-color: #fff;
  margin: 30px auto;
}
.user_main >>> .el-tabs__item {
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  font-size: 18px;
}
.user_main >>> .el-tabs__content {
  height: 520px;
  padding: 30px 50px;
}
.user_main >>> .el-input__inner {
  width: 300px;
}
.user_main >>> .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #ff7b33;
}
.user_main >>> .el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
  color: #ff7b33;
}
.user_main >>> .el-button--primary {
  background-color: #ff7b33;
  border-color: #ff7b33;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader >>> .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
.avatar-uploader1 {
  display: inline-block;
  margin-right: 20px;
}
.avatar-uploader1 >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.tip {
  margin-bottom: 30px;
  font-size: 16px;
  color: #000;
}
.settings_main .el-textarea {
  width: 520px;
  display: block;
  margin: 30px 0;
}
.item_name {
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  color: #000;
}
.box_item {
  display: flex;
  justify-content: space-between;
  width: 430px;
  margin-top: 30px;
  height: 60px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
}
.changeButton {
  width: 80px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #ff7b33;
  border-radius: 18px;
  text-align: center;
  color: #ff7b33;
  cursor: pointer;
  margin-top: 12px;
}
.box_item_phone {
  color: #ff6600;
  margin-left: 8px;
}
.tip_item {
  font-size: 14px;
  font-weight: 400;
  color: #909399;
}
.box_item_email {
  color: #909399;
  margin-left: 8px;
}
.password_item {
  margin-top: 15px;
}
#settings >>> .el-dialog--center .el-dialog__body {
  padding: 25px 75px 30px;
}
.input-box {
  display: flex;
  justify-content: center;
  line-height: 40px;
  height: 40px;
  margin-top: 20px;
}
.input-box >>> .el-input {
  width: 300px;
}
.input-box span {
  text-align: right;
}
.flexS {
  margin-left: 10px;
}
.changeYzm {
  width: 180px !important;
  margin-right: 7px;
}
.el-button--primary {
  background-color: #ff7b33;
  border-color: #ff7b33;
}
.resizeNone >>> .el-textarea__inner {
  resize: none;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
